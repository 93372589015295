<template>
  <div>

    <v-row style="margin-top: 50px" v-if="loading">
      <v-col cols="12" class="text-center">
        <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
      </v-col>
    </v-row>

    <v-form v-else v-model="valid" ref="formDispositivo" lazy-validation>
      <v-container fluid>
        <v-widget title="Informações do Dispositivo" :temCampoObrigatorio="true">
          <div slot="widget-header-action">
            <v-btn text @click="lerInformacoes" data-cy="gerar">
              <v-icon left>devices_other</v-icon>
              Detectar
            </v-btn>
          </div>
          <div slot="widget-content">
            <v-row dense>
              <v-col cols="12" sm="4">
                <v-text-field label="Chave *" v-model="dispositivo.chave" readonly data-cy="chave" :rules="validarPreenchimento"></v-text-field>
              </v-col>
              <v-col cols="12" sm="12">
                <v-text-field label="Nome *" v-model="dispositivo.nome" :rules="validarNome" required data-cy="nome"></v-text-field>
              </v-col>
              <v-col cols="12" sm="8">
                <v-text-field label="Navegador" v-model="dispositivo.navegador" :rules="validarPreenchimento" readonly data-cy="navegador"></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field label="Versão do Navegador" v-model="dispositivo.versao_navegador" :rules="validarPreenchimento" readonly data-cy="versao_navegador"></v-text-field>
              </v-col>
              <v-col cols="12" sm="8">
                <v-text-field label="Sistema Operacional" v-model="dispositivo.sistema_operacional" :rules="validarPreenchimento" readonly data-cy="sistema_operacional"></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field label="Versão do Sistema Operacional" v-model="dispositivo.versao_sistema_operacional" :rules="validarPreenchimento" readonly data-cy="versao_sistema_operacional"></v-text-field>
              </v-col>
              <v-col cols="12" sm="12">
                <v-textarea label="Observação" v-model="dispositivo.observacao" data-cy="observacao"></v-textarea>
              </v-col>
            </v-row>
          </div>
          <template v-slot:widget-footer-action>
            <v-btn text @click="cancelar">{{constantes.BOTAO_CANCELAR}}</v-btn>
            <v-btn color="primary" :loading="carregando" @click="salvar" :disabled="!valid" data-cy="salvar">{{textoBtnConfirmar}}</v-btn>
          </template>
        </v-widget>
      </v-container>
    </v-form>  

  </div>
</template>


<script>

import {mapState, mapGetters, mapActions} from "vuex";
import constantes from '@/util/constantes';

export default {

  data: () => ({
    valid: false,
    validarNome: [v => !!v || "Nome Obrigatório"],
    validarPreenchimento: [v => !!v || 'Campo Obrigatório'],
    constantes: constantes,
    loading: false
  }),

  computed: {

    ...mapState('dispositivo', {
      dispositivo: 'dispositivoAtual',
      carregando: 'carregando'
    }),
    
    ...mapGetters('dispositivo', {
      estaEditando: 'estaEditando',
      textoBtnConfirmar: 'textoBtnConfirmar'
    })
  },

  methods: {
    
    async salvar() {
      if (this.$refs.formDispositivo.validate()) {

        try{
          await this.$store.dispatch('dispositivo/salvar');
          this.$router.push({ name: 'dispositivos' });
        }
        catch(erro){
          console.log('houve um erro');
        }
      }
    },

    cancelar() {
      this.$router.push({ name: 'dispositivos' });
    },

    lerInformacoes(){
      this.$store.dispatch('dispositivo/lerInformacoes');
    }
  },

  async created(){
    
    try{

      this.loading = true;

      let dispositivo = this.$store.state.dispositivo.dispositivoAtual;
      let estaEditando = this.$router.currentRoute.path.indexOf('editar') !== -1 ? true : false;
  
      if(estaEditando && (!dispositivo._id || dispositivo._id === '')){
        this.$store.commit('setMensagemErro', 'Selecione um dispositivo para editar');
        this.$router.push('/dispositivos');
      }
  
      if(!estaEditando){
        this.$store.commit('dispositivo/estadoInicial');
      }
    }
    catch(erro){
      console.log(erro);
    }
    finally{
      this.loading = false;
    }
  }
};
</script>
